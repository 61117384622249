<template>
  <div ref="root" :style="style"></div>
</template>

<script>
import echarts from "../charts";
import client from "../utils/client";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      chart: null,
      registerMap: false,
    };
  },
  computed: {
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      delete result["border-color"];
      delete result["color"];
      delete result["background-color"];
      return result;
    },
  },
  mounted() {
    this.change();
  },
  beforeDestroy() {
    if (this.chart) {
      let dom = this.chart.getDom();
      this.chart.dispose();
      if (dom) this.$refs.root.removeChild(dom);
      this.chart = null;
    }
  },
  watch: {
    "control.size": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.style": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  methods: {
    change() {
      if (!this.chart) {
        let canvas = document.createElement("canvas");
        canvas.className = "fill-height";
        this.$refs.root.appendChild(canvas);
        this.chart = echarts.init(canvas, null, {
          width: this.control.size.width,
          height: this.control.size.height,
        });
        this.chart.on("click", "series", this.onClick);
      } else {
        this.chart.resize({
          width: this.control.size.width,
          height: this.control.size.height,
        });
      }
      let data = [];
      let stateData = this.control.config.stateData.toString().split(",");
      let alarmData = this.control.config.alarmData.toString().split(",");
      for (let i in this.control.config.tabs) {
        let state = stateData[i];
        let alarm = alarmData[i];
        let value = 0;
        if (state && alarm) {
          if (alarm == 9) {
            value = 2;
          } else if (alarm == 7 || alarm == 8) {
            value = 4;
          } else {
            value = 3;
          }
        } else if (state) {
          if (state == 1) {
            value = 4;
          } else {
            value = 1;
          }
        } else if (alarm) {
          if (alarm == 7 || alarm == 8) {
            value = 4;
          } else {
            value = 3;
          }
        }
        data.push({
          name: this.control.config.tabs[i].name,
          value,
        });
      }
      let map = this.control.config.map || "beijing";
      if (map == "other") {
        map = this.control.uuid;
        if (this.control.config.mapJson) {
          echarts.registerMap(map, this.control.config.mapJson);
        } else {
          map = "beijing";
        }
      }
      this.chart.setOption(
        {
          visualMap: {
            type: "piecewise",
            show: this.control.config.legend != "hidden",
            pieces: [
              { value: 1, label: this.$vuetify.lang.t("$vuetify.key.K_%E8%84%B1%E6%9C%BA"), color: "#888" },
              { value: 2, label: this.$vuetify.lang.t("$vuetify.key.K_%E8%84%B1%E6%9C%BA%E5%91%8A%E8%AD%A6"), color: "#A66" },
              { value: 3, label: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6"), color: "#F88" },
              { value: 4, label: this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%B8%B8"), color: "#8F8" },
            ],
            outOfRange: {
              color: this.control.style["background-color"] || "#B3E5FC",
            },
            top: this.control.config.legend.indexOf("top") != -1 ? "0" : "auto",
            right:
              this.control.config.legend.indexOf("right") != -1 ? "0" : "auto",
            bottom:
              this.control.config.legend.indexOf("bottom") != -1 ? "0" : "auto",
            left:
              this.control.config.legend.indexOf("left") != -1 ? "0" : "auto",
            textStyle: {
              color: this.control.style["background-color"] || "#B3E5FC",
            },
          },
          series: [
            {
              type: "map",
              top: "1%",
              right: "1%",
              bottom: "1%",
              left: "1%",
              map: map,
              selectedMode: false,
              label: {
                show: true,
                color: this.control.style.color || "#000",
              },
              itemStyle: {
                areaColor: this.control.style["background-color"] || "#B3E5FC",
                borderColor: this.control.style["border-color"] || "#888",
              },
              emphasis: {
                label: {
                  color: this.control.style.color || "#000",
                },
                itemStyle: {
                  areaColor:
                    this.control.style["background-color"] || "#B3E5FC",
                  borderWidth: 2,
                },
              },
              data: data,
            },
          ],
        },
        !this.isView
      );
    },
    onClick(args) {
      if (this.isView) {
        for (let i in this.control.config.tabs) {
          if (args.name == this.control.config.tabs[i].name) {
            if (this.control.config.tabs[i].pageId) {
              client.$emit("navigate", {
                page: { uuid: this.control.config.tabs[i].pageId },
              });
            }
          }
        }
      }
    },
  },
};
</script>
